import React from "react"
import { useEffect } from "react"
import Recaptcha from "react-google-recaptcha"
import Layout from "../components/layout-static"
import Seo from "../components/seo"

const Page = () => {
  useEffect(() => {
    let bgReferrer = localStorage.getItem("bgReferrer");
    if (bgReferrer) {
      let referrer = JSON.parse(bgReferrer);
      document.getElementById('contactSourceField').value = referrer.value;
    }
  });


  const metadata = {
    title: "Contact Us | Ct Personal Injury Law Firm |  Bartlett & Grippe",
    heroH1: "Contact us",
    heroH2: "When experience, integrity, and results matter.",
    heroImage: "blg-contact-us.jpg",
    heroImageAlt: "Photo of a desk with a laptop and a phone",
    description:
      "Don't delay, contact us by phone, email, text, or chat for immediate help. We're available 24/7 to assist you and your family. Call now for prompt attention.",
  }
  const [nameError, setNameError] = React.useState("")
  const [contactError, setContactError] = React.useState("")
  const [messageError, setMessageError] = React.useState("")
  const [captcahError, setCaptcahError] = React.useState("")

  const recaptchaRef = React.createRef()
  const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY

  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">Bartlett & Grippe, LLC</h2>
            <h3 className="uppercase">Serious Injury Trial Attorneys</h3>
            <p className="mb-8">
              Please contact us via phone, email, text, or chat as soon as
              possible because a delay can greatly affect your results. We are
              here to help you and your family twenty-four hours a day, three
              hundred sixty-five days a year. Call now.
            </p>
            <p className="mb-8">
              <strong>
                You are under no obligation and initial consultations are free.
              </strong>
            </p>
          </div>
          <div className="text-center flex flex-wrap justify-center gap-x-8">
            <div className="mb-8">
              <a className="text-2xl text-white mb-8 bg-phone-number" href="tel:1-203-463-4939">
                <button
                  type="button"
                  className="bg-green-400 text-white font-bold pb-3 pt-2 px-4 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 inline"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <div className="ml-2 inline">Call <span class="bg-phone-number">203-463-4939</span></div>
                </button>
              </a>
            </div>
            <div className="mb-8">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-2xl text-white mb-8"
                href="https://www.intaker.com/bartlettgrippe/personal-injury"
              >
                <button
                  type="button"
                  className="bg-green-400 text-white font-bold pb-3 pt-2 px-4 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 inline"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      strokeWidth="2"
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                    />
                  </svg>
                  <div className="ml-2 inline">Chat</div>
                </button>
              </a>
            </div>
          </div>
          <div className="mb-16" id="contactForm">
            <div className="bg-gray-900 p-8 xl:p-8 mb-16">
              <h2 className="font-bold text-center text-white">
                Free Consultation
              </h2>
              <form
                name="contacts"
                id="contacts"
                method="POST"
                action="/firm-overview/thank-you/"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={event => {
                  if (RECAPTCHA_KEY && !recaptchaRef.current.getValue()) {
                    event.preventDefault()
                    setCaptcahError("* Captcha is required")
                  }
                  if (event.target.name.value.trim().length < 1) {
                    setNameError("* Name is required")
                    event.preventDefault()
                  } else {
                    setNameError("")
                  }
                  if (
                    event.target.phone.value.trim().length < 7 &&
                    event.target.email.value.trim().length < 7
                  ) {
                    setContactError("* Email or Phone is required")
                    event.preventDefault()
                  } else {
                    setContactError("")
                  }
                  if (event.target.message.value.trim().length < 1) {
                    setMessageError("* Description is required")
                    event.preventDefault()
                  } else {
                    setMessageError("")
                  }
                }}
              >

                <p class="hidden">
                  <label>
                    Don't fill this out if you're human: <input name="bot-field" />
                  </label>
                </p>

                <input type="hidden" name="form-name" value="contacts" />
                <input type="hidden" name="source" id="contactSourceField" value="bartlettgrippe.com" />
                <label className="block text-white text-lg mb-2">
                  Name{" "}
                  {nameError.length ? (
                    <div className="text-red-500">{nameError}</div>
                  ) : null}
                  <input
                    type="text"
                    className="text-gray-900 rounded w-full mb-4 p-2"
                    name="name"
                    id="contacts-name"
                  />
                </label>
                <label className="block text-white text-lg mb-2">
                  Phone{" "}
                  {contactError.length ? (
                    <div className="text-red-500">{contactError}</div>
                  ) : null}
                  <input
                    type="text"
                    className="text-gray-900 rounded w-full mb-4 p-2"
                    name="phone"
                    id="contacts-phone"
                  />
                </label>
                <label className="block text-white text-lg mb-2">
                  Email{" "}
                  {contactError.length ? (
                    <div className="text-red-500">{contactError}</div>
                  ) : null}
                  <input
                    type="text"
                    className="text-gray-900 rounded w-full mb-4 p-2"
                    name="email"
                    id="contacts-email"
                  />
                </label>
                <label className="block text-white text-lg mb-2">
                  Description{" "}
                  {messageError.length ? (
                    <div className="text-red-500">{messageError}</div>
                  ) : null}
                  <textarea
                    type="text"
                    className="text-gray-900 rounded w-full mb-4 p-2 h-32"
                    name="message"
                    id="contacts-message"
                  />
                </label>
                {captcahError.length ? (
                  <label className="block text-white text-lg mb-2">
                    <div className="text-red-500">{captcahError}</div>
                  </label>
                ) : null}
                {RECAPTCHA_KEY && (
                  <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
                )}
                <button
                  type="submit"
                  class="block bg-green-400 text-white rounded py-1 pl-2 pr-3 font-semibold"
                >
                  <svg
                    class="w-8 inline mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokelinecap="round"
                      strokelinejoin="round"
                      strokewidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    ></path>
                  </svg>
                  Get Help Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
